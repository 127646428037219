import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CreateQueueCommand, CreateQueueResponseDto } from '@core/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TransactionV1Service {
  private baseUrl: string = environment.api.baseUrl;

  private readonly http: HttpClient = inject(HttpClient);

  createQueueing(
    command: CreateQueueCommand
  ): Observable<CreateQueueResponseDto> {
    return this.http.post<CreateQueueResponseDto>(
      `${this.baseUrl}/v1/transactions/queueing`,
      command
    );
  }
}
