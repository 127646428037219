import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CategoryDto } from '@core/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoriesV1Service {
  private baseUrl: string = environment.api.baseUrl;

  private readonly http: HttpClient = inject(HttpClient);

  getCategories(): Observable<CategoryDto[]> {
    let url_ = this.baseUrl + '/api/v1/categories';
    url_ = url_.replace(/[?&]$/, '');

    return this.http.get<CategoryDto[]>(url_);
  }
}
