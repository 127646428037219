import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  RestaurantDto,
  PaginatedListOfRestaurantBranchSearchDto,
  PaginatedListOfRestaurantBranchQueueingDto,
} from '@core/models';

import {
  SearchRestaurantQuery,
  GetRestaurantQueueingsQuery,
} from '@core/models/requests';

import { environment } from '@env/environment';
import { toQueryString } from '@shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class RestaurantV1Service {
  private baseUrl: string = environment.api.baseUrl;

  private readonly http: HttpClient = inject(HttpClient);

  getRestaurant(id: string): Observable<RestaurantDto> {
    return this.http.get<RestaurantDto>(`${this.baseUrl}/v1/restaurants/${id}`);
  }

  getRestaurantBranch(
    restaurantId: string,
    branchId: string
  ): Observable<RestaurantDto> {
    return this.http.get<RestaurantDto>(
      `${this.baseUrl}/api/v1/restaurants/${restaurantId}/branches/${branchId}`
    );
  }

  searchRestaurants(
    query: SearchRestaurantQuery
  ): Observable<PaginatedListOfRestaurantBranchSearchDto> {
    let params = toQueryString(query);
    let url = `${this.baseUrl}/api/v1/restaurants?${params}`;

    return this.http.get<PaginatedListOfRestaurantBranchSearchDto>(url);
  }

  getRestaurantBranchQueueings(
    query: GetRestaurantQueueingsQuery
  ): Observable<PaginatedListOfRestaurantBranchQueueingDto> {
    let params = toQueryString(query);
    let url = `${this.baseUrl}/api/v1/restaurants/queueings?${params}`;

    return this.http.get<PaginatedListOfRestaurantBranchQueueingDto>(url);
  }
}
