import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserProfileDto } from '@core/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserV1Service {
  private baseUrl: string = environment.api.baseUrl;

  private readonly http: HttpClient = inject(HttpClient);

  getUserProfile(): Observable<UserProfileDto> {
    let url_ = this.baseUrl + '/api/v1/users/profile';
    url_ = url_.replace(/[?&]$/, '');

    return this.http.get<UserProfileDto>(url_);
  }

  addFavorites(restaurantBranchId: string): Observable<any> {
    let url_ = `${this.baseUrl}/api/v1/users/restaurants/${restaurantBranchId}/favorites`;
    url_ = url_.replace(/[?&]$/, '');
    return this.http.post(url_, {});
  }

  getFavorites(opts: any): Observable<any> {
    const queryParams = Object.keys(opts).map((key) => `${key}=${opts[key]}`).join('&');
    let url_ = `${this.baseUrl}/api/v1/users/restaurants/favorites?${queryParams}`;
    url_ = url_.replace(/[?&]$/, '');
    return this.http.get(url_, {});
  }
}
